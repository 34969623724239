import React, { useEffect, useRef } from "react"
import {
  DropDown,
  DropDownHeader,
  DropDownTitle,
  DropDownMenu,
  DropDownList,
  DropDownListItem,
} from "./dropdown"

function PriceSort({ isActive, dir, onChange, onClose }) {
  const viewRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (viewRef.current && !viewRef.current.contains(event.target)) {
        // Clicked outside the view
        handleOutsideClick()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOutsideClick = () => {
    onClose()
  }

  return (
    <DropDown
      ref={viewRef}
      className={`dropdown-menu ${isActive ? "active" : ""}`}
      style={{ zIndex: 10 }}
    >
      <DropDownHeader className="dropdown-header">
        <DropDownTitle className="dropdown-title">
          Sorting Options
        </DropDownTitle>
      </DropDownHeader>
      <DropDownMenu className="dropdown-menu">
        <DropDownList>
          <DropDownListItem>
            <div className="form-check">
              <input
                type="radio"
                name="sort"
                value="desc"
                className="form-check-input"
                id="highPrice"
                defaultChecked
                checked={dir === "desc"}
                onClick={() => {
                  if (dir !== "desc") onChange("desc")
                  onClose()
                }}
              />
              <label className="form-check-label" htmlFor="highPrice">
                High to Low Price
              </label>
            </div>
          </DropDownListItem>
          <DropDownListItem>
            <div className="form-check">
              <input
                type="radio"
                name="sort"
                value="asc"
                className="form-check-input"
                checked={dir === "asc"}
                onClick={() => {
                  if (dir !== "asc") onChange("asc")
                  onClose()
                }}
                id="lowPrice"
              />
              <label className="form-check-label" htmlFor="lowPrice">
                Low to High Price
              </label>
            </div>
          </DropDownListItem>
        </DropDownList>
      </DropDownMenu>
    </DropDown>
  )
}

export default PriceSort
